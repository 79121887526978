import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M 0 59.71 L 0 0 L 45.22 0 L 45.22 59.71 L 39.69 59.71 L 24.78 18.69 L 11.76 18.69 L 11.76 23.17 L 19.04 23.17 L 5.53 59.71 L 0 59.71 Z M 85.12 79.1 L 43.54 79.1 L 43.54 0 L 85.12 0 L 85.12 79.1 Z M 66.5 18.69 L 47.18 18.69 L 47.18 23.17 L 52.92 23.17 L 52.92 59.71 L 47.18 59.71 L 47.18 64.19 L 65.87 64.19 L 65.87 59.71 L 58.03 59.71 L 58.03 47.25 L 66.92 47.25 A 19.773 19.773 0 0 0 71.987 46.632 A 14.851 14.851 0 0 0 77.98 43.505 Q 82.32 39.76 82.32 32.62 Q 82.32 26.95 78.645 22.82 A 10.922 10.922 0 0 0 74.33 19.925 Q 71.132 18.69 66.5 18.69 Z M 45.22 64.19 L 45.22 79.1 L 0 79.1 L 0 64.19 L 15.96 64.19 L 15.96 59.71 L 10.36 59.71 L 13.58 50.96 L 31.78 50.96 L 34.86 59.71 L 29.47 59.71 L 29.47 64.19 L 45.22 64.19 Z M 66.29 42.77 L 58.03 42.77 L 58.03 23.17 L 66.08 23.17 Q 71.68 23.17 74.27 25.76 A 8.631 8.631 0 0 1 76.626 30.272 A 12.578 12.578 0 0 1 76.86 32.76 A 13.618 13.618 0 0 1 76.317 36.782 Q 74.465 42.77 66.29 42.77 Z M 22.61 26.11 L 22.96 26.11 L 30.38 46.97 L 15.05 46.97 L 22.61 26.11 Z"
          fill="currentColor"
        />
      </g>
      
    </g>
  </svg>
);

export default IconLoader;
